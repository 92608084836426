<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import eventService from "@/services/eventService";
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    EventGrid,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
    // redirect to profile (and force authentication)
    this.$router.replace({ name: "profile", query: { } });
  }, 
};
</script>

<style lang="scss" scoped>
 
</style> 